





































































import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @PropSync('check', { type: Boolean })
  syncedCheck!: boolean

  @PropSync('alertPattern', { type: String })
  syncedAlertPattern!: string

  @PropSync('temperatureThreshold', { type: Number })
  syncedTemperatureThreshold!: number

  // 人数によるアラート機能は今後実装される予定のためコメントアウト
  // @PropSync('personNumberThreshold', { type: Number })
  // syncedPersonNumberThreshold!: number

  @PropSync('percentageThreshold', { type: Number })
  syncedPercentageThreshold!: number

  get temperatureThresholdList() {
    let thresholdList: Array<number> = []
    const minThreshold = 37
    const maxThreshold = 39
    const interval = 0.1
    
    for (let threshold = minThreshold * 10; threshold <= maxThreshold * 10; threshold += interval * 10) {
      thresholdList.push(threshold / 10)
    }
    return thresholdList
  }

  get percentageThresholdList() {
    // 「1~5」は1%刻み、「5~」は5%刻みで選択できるようにする（現状MAXは20）
    let thresholdList: Array<number> = [1, 2, 3, 4]
    const minThreshold = 5
    const maxThreshold = 20
    const interval = 5
    
    for (let threshold = minThreshold; threshold <= maxThreshold; threshold += interval) {
      thresholdList.push(threshold)
    }
    return thresholdList
  }
}
