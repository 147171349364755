import config from '@/config'
import store from '@/store'
import axios, { AxiosResponse } from 'axios'
import { Action, Mutation, getModule, Module, VuexModule } from 'vuex-module-decorators'

export const ErrorMsg = {
  FetchSymptomAlertFailureError: '有症状者アラート設定を取得できませんでした',
  SaveSymptomAlertFailureError: '有症状者アラート設定の保存に失敗しました',
} as const

export type TSymptomAlert = {
  isEnabled: boolean
  percentageThreshold: number
}

export const DEFAULT_SYMPTOM_ALERT = {
  isEnabled: false,
  percentageThreshold: 20,
} as const

@Module({ dynamic: true, namespaced: true, name: 'symptomAlert', store })
class Mod extends VuexModule {
  symptomAlert: TSymptomAlert | null = null

  @Mutation
  setSymptomAlert(symptomAlert: TSymptomAlert | null): void {
    this.symptomAlert = symptomAlert
  }

  @Action({ commit: 'setSymptomAlert', rawError: true })
  async fetchSymptomAlert(): Promise<TSymptomAlert | null> {
    const url = `${config.enterprise.apiUrl}/symptom_alerts`
    try {
      const res = await axios.get(url)
      if (!Object.keys(res.data).length) {
        return null
      }
      return {
        isEnabled: res.data.isEnabled,
        percentageThreshold: res.data.percentageThreshold,
      }
    } catch {
      throw new Error(ErrorMsg.FetchSymptomAlertFailureError)
    }
  }

  @Action({ rawError: true })
  async saveSymptomAlert(symptomAlert: TSymptomAlert): Promise<AxiosResponse> {
    const url = `${config.enterprise.apiUrl}/symptom_alerts`

    try {
      const res = await axios.post(url, symptomAlert)
      return res
    } catch {
      throw new Error(ErrorMsg.SaveSymptomAlertFailureError)
    }
  }
}

export default getModule(Mod)
