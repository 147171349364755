













































import FeverAlertItem from '@/components/organisms/Alert/FeverAlertItem.vue'
import SymptomAlertItem from '@/components/organisms/Alert/SymptomAlertItem.vue'
import VABox from 'va/widgets/VABox.vue'
import feverAlertStore, { TFeverAlert, DEFAULT_FEVER_ALERT } from '@/store/FeverAlert'
import symptomAlertStore, { TSymptomAlert, DEFAULT_SYMPTOM_ALERT } from '@/store/SymptomAlert'
import menuStore from '@/store/Menu'
import { Component, Vue, Watch } from 'vue-property-decorator'
import Spinner from '@/components/atoms/Spinner.vue'

@Component({
  components: { VABox, FeverAlertItem, SymptomAlertItem, Spinner },
})
export default class extends Vue {
  isLoading = false
  feverAlert: TFeverAlert = DEFAULT_FEVER_ALERT
  symptomAlert: TSymptomAlert = DEFAULT_SYMPTOM_ALERT

  @Watch('$route')
  mounted(): void {
    menuStore.setActiveFlg({ settings: true })
  }

  get getFeverAlert(): TFeverAlert {
    return this.feverAlert
  }

  get getSymptomAlert(): TSymptomAlert {
    return this.symptomAlert
  }

  async created(): Promise<void> {
    this.isLoading = true
    try {
      await this.fetchAlerts()
    } finally {
      this.isLoading = false
    }
  }

  async completeSetting(): Promise<void> {
    this.isLoading = true
    try {
      await this.saveAlerts()
      await this.fetchAlerts()
    } finally {
      this.isLoading = false
    }
  }

  private async fetchAlerts(): Promise<void> {
    await Promise.all([feverAlertStore.fetchFeverAlert(), symptomAlertStore.fetchSymptomAlert()])

    if (feverAlertStore.feverAlert !== null) this.feverAlert = feverAlertStore.feverAlert
    if (symptomAlertStore.symptomAlert !== null) this.symptomAlert = symptomAlertStore.symptomAlert
  }

  private async saveAlerts(): Promise<void> {
    await Promise.all([
      feverAlertStore.saveFeverAlert(this.getFeverAlert),
      symptomAlertStore.saveSymptomAlert(this.getSymptomAlert),
    ])
      .then(() => {
        alert('アラート設定の保存が完了しました。')
      })
      .catch(() => {
        alert('アラート設定の保存に失敗しました。')
      })
  }
}
