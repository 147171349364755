import config from '@/config'
import store from '@/store'
import axios, { AxiosResponse } from 'axios'
import { Action, Mutation, getModule, Module, VuexModule } from 'vuex-module-decorators'

export const ErrorMsg = {
  FetchFeverAlertFailureError: '発熱者アラート設定を取得できませんでした',
  SaveFeverAlertFailureError: '発熱者アラート設定の保存に失敗しました',
} as const

export type TFeverAlert = {
  isEnabled: boolean
  alertPattern: AlertPatternType
  temperatureThreshold: number
  personNumberThreshold: number
  percentageThreshold: number
}

export const AlertPattern = {
  NUMBER_OF_PEOPLE: 'number_of_people',
  PERCENTAGE: 'percentage',
} as const

export type AlertPatternType = typeof AlertPattern[keyof typeof AlertPattern]

export const DEFAULT_FEVER_ALERT = {
  isEnabled: false,
  alertPattern: AlertPattern.PERCENTAGE,
  temperatureThreshold: 37.5,
  personNumberThreshold: 20,
  percentageThreshold: 20,
} as const

@Module({ dynamic: true, namespaced: true, name: 'feverAlert', store })
class Mod extends VuexModule {
  feverAlert: TFeverAlert | null = null

  @Mutation
  setFeverAlert(feverAlert: TFeverAlert | null): void {
    this.feverAlert = feverAlert
  }

  @Action({ commit: 'setFeverAlert', rawError: true })
  async fetchFeverAlert(): Promise<TFeverAlert | null> {
    const url = `${config.enterprise.apiUrl}/fever_alerts`
    try {
      const res = await axios.get(url)
      if (!Object.keys(res.data).length) {
        return null
      }
      return {
        isEnabled: res.data.isEnabled,
        alertPattern: res.data.alertPattern,
        temperatureThreshold: parseFloat(res.data.temperatureThreshold),
        personNumberThreshold: res.data.personNumberThreshold,
        percentageThreshold: res.data.percentageThreshold,
      }
    } catch {
      throw new Error(ErrorMsg.FetchFeverAlertFailureError)
    }
  }

  @Action({ rawError: true })
  async saveFeverAlert(feverAlert: TFeverAlert): Promise<AxiosResponse> {
    const url = `${config.enterprise.apiUrl}/fever_alerts`

    try {
      const res = await axios.post(url, feverAlert)
      return res
    } catch {
      throw new Error(ErrorMsg.SaveFeverAlertFailureError)
    }
  }
}

export default getModule(Mod)
