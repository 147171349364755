

































import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @PropSync('check', { type: Boolean })
  syncedCheck!: boolean

  @PropSync('percentageThreshold', { type: Number })
  syncedPercentageThreshold!: number

  get percentageThresholdList() {
    // 「1~5」は1%刻み、「5~」は5%刻みで選択できるようにする（現状MAXは20）
    let thresholdList: Array<number> = [1, 2, 3, 4]
    const minThreshold = 5
    const maxThreshold = 20
    const interval = 5
    
    for (let threshold = minThreshold; threshold <= maxThreshold; threshold += interval) {
      thresholdList.push(threshold)
    }
    return thresholdList
  }
}
